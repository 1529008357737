<!-- <section id="wrapper" class="login-register">
    <div class="login-box" style="color: #3DF712; width: 700px; margin-top: 70px;">
        <div *ngIf="signUpForm" class="white-box">
            <span class="text-center db" style="font-weight: bold;">
                Sign Up As Admin
            </span><hr/>
            <form class="form-horizontal new-lg-form" id="login" name="login" [formGroup]="form" (ngSubmit)="submitForm()" style="background-color: transparent;">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Company Name</label>
                            <div class="col-lg-12">
                                <input type="text" class="form-control" placeholder="Company Name" style="border-color: #3DF712;"
                                formControlName="com_name" [ngClass]="{ 'is-invalid': submitted && f.com_name.errors }">
                                <div *ngIf="submitted && f.com_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.com_name.errors.required" style="color: red;margin-top: -25px;">Company Name is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Business License number</label>
                            <div class="col-lg-12">
                                <input type="text" class="form-control" placeholder="Business License number" formControlName="business_licence" style="border-color: #3DF712;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Country Code</label>
                            <div class="col-lg-12">
                                <select type="text" class="form-control" style="border-color: #3DF712;"
                                formControlName="com_country_code" [ngClass]="{ 'is-invalid': submitted && f.com_country_code.errors }">
                                    <option value="1" selected="true">USA</option>
                                </select>
                                <div *ngIf="submitted && f.com_country_code.errors" class="invalid-feedback">
                                    <div *ngIf="f.com_country_code.errors.required" style="color: red;margin-top: -25px;">Country Code is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Mobile No</label>
                            <div class="col-lg-12">
                                <input type="text" class="form-control" placeholder="Mobile No" style="border-color: #3DF712;"
                                formControlName="com_mobile_number" [ngClass]="{ 'is-invalid': submitted && f.com_mobile_number.errors }">
                                <div *ngIf="submitted && f.com_mobile_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.com_mobile_number.errors.required" style="color: red;margin-top: -25px;">Mobile number is required</div>
                                    <div *ngIf="f.com_mobile_number.errors.pattern" style="color: red;margin-top: -25px;">Mobile number must be 10 numbers.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Email</label>
                            <div class="col-lg-12">
                                <input type="text" class="form-control" placeholder="Email" style="border-color: #3DF712;"
                                formControlName="com_email" [ngClass]="{ 'is-invalid': submitted && f.com_email.errors }">
                                <div *ngIf="submitted && f.com_email.errors" class="invalid-feedback">
                                    <div *ngIf="f.com_email.errors.required" style="color: red;margin-top: -25px;">Company Email is required</div>
                                    <div *ngIf="f.com_email.errors.email" style="color: red;margin-top: -25px;">Please Enter Valid Company Email</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Password</label>
                            <div class="col-lg-12">
                                <input type="password" class="form-control" placeholder="Password" style="border-color: #3DF712;"
                                formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required" style="color: red;margin-top: -25px;">Password is required</div>
                                    <div *ngIf="f.password.errors.minlength" style="color: red;margin-top: -25px;">Password must be at least 6 characters long</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="col-lg-12">Confirm Password</label>
                            <div class="col-lg-12">
                                <input type="password" class="form-control" placeholder="Confirm Password"formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" style="border-color: #3DF712;">
                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required" style="color: red;margin-top: -25px;">Confirm password is required</div>
                                    <div *ngIf="f.confirmPassword.errors.minlength" style="color: red;margin-top: -25px;">Confirm Password must be at least 6 characters long</div>
                                    <div *ngIf="f.confirmPassword.errors.confirmedValidator" style="color: red;margin-top: -25px;">Password and Confirm password must be match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button [disabled]="loading" class="btn btn-lg btn-block text-white"  type="submit" style="background-color: #3DF712;">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Sign Up
                        </button>
                    </div>
                </div>
                <div class="text-center">OR</div>
                <div class="text-center" style="margin-bottom: 10px;">
                    Already have an account? <a [routerLink]="['/admin_login/']" id="to-recover" class="text-dark" style="color: #3DF712;">
                     <span style="color: #3DF712;font-weight: 600;">Sign In</span></a>
                </div>
            </form>
        </div>
        <div *ngIf="otpForm" class="white-box" style="background-color: white">
            <span class="text-center db" style="font-weight: bold;">
                Sign Up As Admin
            </span><hr/>
            <div class="row">
                <div class="col-md-2">&nbsp;</div>
                <div class="col-md-12">
                    <label class="col-lg-12">OTP</label>
                    <div class="col-lg-12">
                        <input type="number" class="form-control" placeholder="Please enter otp" [(ngModel)]="otp" style="border-color:#3DF712"/>
                    </div>
                </div>
                <div class="col-md-12">
                    <label class="col-lg-12">&nbsp;</label>
                    <div class="col-lg-12">
                        <button class="btn btn-sm btn-block text-white"  type="button" style="background-color: #3DF712; line-height: 1.2;" (click)="varifyOtpOnSignUp()">
                            Submit
                        </button>
                    </div>
                </div>
                <div class="col-md-2">&nbsp;</div>
            </div>
        </div>
    </div>
</section> -->
<section id="wrapper" class="login-register">

    <!-- <div class="loader" *ngIf="isLoading">
        <div class="loader_inner"></div>
    </div> -->

	<div *ngIf="signUpForm" class="box">
		<h4>Employer Sign Up</h4>
		<form [formGroup]="form" (ngSubmit)="submitForm()">
            <div class="row">
                <div class="col-md-12">
                    <div class="inputBox">
                        <input type="text" formControlName="com_name" [ngClass]="{ 'is-invalid': submitted && f.com_name.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.com_name.errors" class="invalid-feedback">
                            <div *ngIf="f.com_name.errors.required" style="color: red;margin-top: -25px;">Company Name is required</div>
                        </div>
                        <label for="">Company Name</label>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="inputBox">
                        <input type="text" formControlName="business_licence" style="border-color: #3DF712;">
                        <label for="">Business License number</label>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="inputBox">
                        <input type="text" formControlName="com_email" [ngClass]="{ 'is-invalid': submitted && f.com_email.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.com_email.errors" class="invalid-feedback">
                            <div *ngIf="f.com_email.errors.required" style="color: red;margin-top: -25px;">Company Email is required</div>
                            <div *ngIf="f.com_email.errors.email" style="color: red;margin-top: -25px;">Please Enter Valid Company Email</div>
                        </div>
                        <label for="">Email</label>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="inputBox">
                        <select type="text" formControlName="com_country_code" [ngClass]="{ 'is-invalid': submitted && f.com_country_code.errors }" style="border-color: #3DF712; color: #3DF712; ">
                            <option value="1" selected="true">USA</option>
                        </select>
                        <div *ngIf="submitted && f.com_country_code.errors" class="invalid-feedback">
                            <div *ngIf="f.com_country_code.errors.required" style="color: red;margin-top: -25px;">Country Code is required</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="inputBox">
                        <input type="text" formControlName="com_mobile_number" [ngClass]="{ 'is-invalid': submitted && f.com_mobile_number.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.com_mobile_number.errors" class="invalid-feedback">
                            <div *ngIf="f.com_mobile_number.errors.required" style="color: red;margin-top: -25px;">Mobile number is required</div>
                            <div *ngIf="f.com_mobile_number.errors.pattern" style="color: red;margin-top: -25px;">Mobile number must be 10 numbers.</div>
                        </div>
                        <label for="">Mobile Number</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-6">
                    <div class="inputBox">
                        <input type="text" formControlName="com_email" [ngClass]="{ 'is-invalid': submitted && f.com_email.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.com_email.errors" class="invalid-feedback">
                            <div *ngIf="f.com_email.errors.required" style="color: red;margin-top: -25px;">Company Email is required</div>
                            <div *ngIf="f.com_email.errors.email" style="color: red;margin-top: -25px;">Please Enter Valid Company Email</div>
                        </div>
                        <label for="">Email</label>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="inputBox">
                        <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required" style="color: red;margin-top: -25px;">Password is required</div>
                            <div *ngIf="f.password.errors.minlength" style="color: red;margin-top: -25px;">Password must be at least 6 characters long</div>
                        </div>
                        <label for="">Password</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="inputBox">
                        <input type="password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required" style="color: red;margin-top: -25px;">Confirm password is required</div>
                            <div *ngIf="f.confirmPassword.errors.minlength" style="color: red;margin-top: -25px;">Confirm Password must be at least 6 characters long</div>
                            <div *ngIf="f.confirmPassword.errors.confirmedValidator" style="color: red;margin-top: -25px;">Password and Confirm password must be match</div>
                        </div>
                        <label for="">Confirm Password</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-md-6">
                    <div class="inputBox">
                        <input type="password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" style="border-color: #3DF712;">
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required" style="color: red;margin-top: -25px;">Confirm password is required</div>
                            <div *ngIf="f.confirmPassword.errors.minlength" style="color: red;margin-top: -25px;">Confirm Password must be at least 6 characters long</div>
                            <div *ngIf="f.confirmPassword.errors.confirmedValidator" style="color: red;margin-top: -25px;">Password and Confirm password must be match</div>
                        </div>
                        <label for="">Confirm Password</label>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="inputBox">
                        <ng-select2  width="100%" placeholder="Select Work Groups" [options]="options" formControlName="custom_cluster_ids" [ngClass]="{ 'is-invalid': submitted && f.custom_cluster_ids.errors }">
                            <option *ngFor="let item of clusterArr" value="{{item._id}}">{{item.cluster_name}}</option>
                        </ng-select2>
                        <div *ngIf="submitted && f.custom_cluster_ids.errors" class="invalid-feedback">
                            <div *ngIf="f.custom_cluster_ids.errors.required" style="color: red;margin-top: -25px;">Cluster is required</div>
                        </div>
                        <label for="">Select Work Group</label>
                    </div>
                </div>
            </div>
            <h4><button type="submit"> <div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div><b>Submit</b></button></h4>
            <p class="text-center color">Already have an account? <a class="signIn" (click)="toSignIn()"><b>Sign In</b></a></p>
		</form>
	</div>
    <div class="boxFooter">
		<!-- <h2>fdsf</h2> -->
		<img src="assets/img/logo2.png" height="30" width="160">
		<h4 class="boxFooterText">Powering<br/>Today's<br/>Workforce</h4>
		<!-- <h5>Today's</h5> -->
		<!-- <h5>Workforce</h5> -->
	</div>
    <div *ngIf="otpForm" class="box">
		<h4>Please enter OTP sent to your registered email id/mobile number.</h4>
        <div class="inputBox">
            <input type="number" [(ngModel)]="otp">
            <label for="">OTP</label>
        </div>
        <h4><button type="submit" (click)="varifyOtpOnSignUp()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div><b>Submit</b></button></h4>
	</div>
</section>



