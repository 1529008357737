<section id="wrapper" class="login-register">
	<div class="box" *ngIf="deleteAccountForm">
		<h4>Delete Account</h4>
		<br/>
		<form [formGroup]="deleteForm">
			<div class="inputBox">
				<input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Name</div>
        </div>
				<label for="">Name</label>
			</div>
      <div class="inputBox">
				<input type="text" formControlName="mobile_number" [ngClass]="{ 'is-invalid': submitted && f.mobile_number.errors }">
        <div *ngIf="submitted && f.mobile_number.errors" class="invalid-feedback">
            <div *ngIf="f.mobile_number.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid Mobile Number</div>
        </div>
				<label for="">Mobile Number</label>
			</div>
      <div class="inputBox">
				<input type="text" formControlName="reason" [ngClass]="{ 'is-invalid': submitted && f.reason.errors }">
				<label for="">Reason</label>
			</div>

			<h4><button type="submit" (click)="deleteAccount()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4>

		</form>
	</div>
  <div class="box" *ngIf="deleteAccountFormOtp">
		<h4>Delete Account OTP</h4>
		<br/>
		<form [formGroup]="deleteOTPForm">
			<div class="inputBox">
				<input type="number" formControlName="otp" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required" style="color: red; margin-top: -25px;">Please Enter Valid OTP</div>
        </div>
				<label for="">OTP</label>
			</div>

			<h4><button type="submit" (click)="deleteAccountOTP()"><div *ngIf="isLoading" class="button_loader"><div class="button_loader_inner"></div></div> <b>Submit</b></button></h4>

		</form>
	</div>
	<div class="boxFooter">
		<img src="assets/img/logo2.png" height="30" width="160">
		<h4 class="boxFooterText">Powering<br/>Today's<br/>Workforce</h4>
	</div>
</section>
