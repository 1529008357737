import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';

// import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: 'https://api.gigpilotworkgroups.com', options: {} };

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { NgSelect2Module } from 'ng-select2';
import { NgbModule,NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { Daterangepicker } from 'ng2-daterangepicker';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LangInterceptor } from './interceptors/lang-interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { I18nModule } from './i18n/i18n.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { DatePipe } from '@angular/common';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { SuperAdminLoginComponent } from './component/super_admin/super_admin_login.component';
import { AdminLoginComponent } from './component/admin/admin_login.component';
import { AdminSignupComponent } from './component/admin_signup/admin_signup.component';
import { ConfirmationDialogComponent } from './component/common/confirmation-dialog/confirmation-dialog.component';
import { SupportComponent } from './component/pages/support/support.component';
import { PrivacyAndSecurityComponent } from './component/pages/privacy-and-security/privacy-and-security.component';
import { AboutUsComponent } from './component/pages/about-us/about-us.component';
import { HomeComponent } from './component/home/home.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { ExcelService } from './services/excel.service';
import { SuccessComponent } from './component/pages/payment/success/success.component';
import { CancelComponent } from './component/pages/payment/cancel/cancel.component';
import { TermsConditionComponent } from './component/pages/terms-condition/terms-condition.component';
import { DeleteAccountComponent } from './component/delete-account/delete-account.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent,
    SuperAdminLoginComponent, AdminLoginComponent, AdminSignupComponent, SupportComponent, PrivacyAndSecurityComponent, AboutUsComponent, HomeComponent, SuccessComponent, CancelComponent, TermsConditionComponent, DeleteAccountComponent,
  ],
  imports: [
    SocketIoModule.forRoot(config),
    GooglePlaceModule,
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 2000, // 15 seconds
      progressBar: true,
    }),
    SweetAlert2Module.forRoot(),
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule,
    // NgSelectModule,
    NgbModule,
    Daterangepicker,
    EditorModule,
    NgSelect2Module,
    I18nModule,
    ContentLoaderModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    IvyCarouselModule
    // CloudinaryModule.forRoot({ Cloudinary }, {
    //   cloud_name: environment.cloudinary_name,
    //   upload_preset: environment.cloudinary_upload_preset
    // } as CloudinaryConfiguration)
  ],
  
  providers: [
    DatePipe,
    NgbCarouselConfig,
    ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: '../assets//plugins/tinymce/js/tinymce/js/tinymce.min.js' },
    // { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
