// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const is_live = true;

export const environment = {
  production: false,
  videoSdkLink: "https://app.videosdk.live/rooms/GIGPILOT/Host_637a130088bca431941b4e20/",
  AWS_ACCESS_KEY_ID: "AKIA54UAOQ7RNYYDMRG4",
  AWS_SECRET_ACCESS_KEY: "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",
  AWS_REGION: "us-east-1",

  // superAdminApiUrl: is_live
  //   ? 'https://api.gigpilot.org/super_admin/'
  //   : 'http://localhost:8080/super_admin/',
  // adminApiUrl: is_live
  //   ? 'https://api.gigpilot.org/admin/'
  //   : 'http://localhost:8080/admin/',
  superAdminApiUrl: is_live
    ? 'https://api.gigpilotworkgroups.com/super_admin/'
    : 'http://localhost:8080/super_admin/',
  adminApiUrl: is_live
    ? 'https://api.gigpilotworkgroups.com/admin/'
    : 'http://localhost:8080/admin/',
  firebase: {
    apiKey: "AIzaSyCm_o0x0RWmTL8Qhp-TuFijjrAe8oZPBYw",
    authDomain: "gigpilot-3a290.firebaseapp.com",
    databaseURL:"https://gigpilot-3a290.firebaseio.com",
    projectId: "gigpilot-3a290",
    storageBucket: "gigpilot-3a290.appspot.com",
    messagingSenderId: "526154436829",
    appId: "1:526154436829:web:c62afda4c5a3d8bf43cb34",
    vapidKey: "BMXNFLwP4nbk169VyLm4ou3w-S1gGa_-tuSVzFqFy7ZintK_DR0lzP5UBzaEDQEWTtMb31nPOWHT62gZYX2LeqE"
  },

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
